<template>
  <div
    class="absolute bottom-5 top-5 grid left-10 ml-12 z-30 bg-white shadow pt-5 w-module rounded-xl"
    :class="[
      table.key === 'geozones_between' ? 'grid-cols-1' : 'grid-cols-2',
      { mainDiv: activeTab === 'sensors' || activeTab === 'addColumns' }
    ]"
    style="grid-template-rows: repeat(4, auto) minmax(0, 1fr); width: 560px"
    :style="
      table.key === 'geozones_between'
        ? { 'grid-template-rows': 'repeat(6, auto) minmax(0, 1fr)' }
        : { 'grid-template-rows': 'repeat(4, auto) minmax(0, 1fr)' }
    "
  >
    <div
      class="pl-6 col-start-1 col-end-2 text-darkblue text-xl font-bold font-SourceSansPro whitespace-no-wrap"
    >
      {{ $t('reports.edit-reports') }}
    </div>
    <div class="pr-4 col-start-2 flex justify-end">
      <i
        class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
        @click="$emit('close')"
      ></i>
    </div>

    <div
      :class="['pr-4 pl-6 col-start-1 col-end-3 flex justify-end pb-3 mt-6']"
    >
      <skif-button class="w-full" variant="normal" @click="close">
        {{ $t('cancel') }}
      </skif-button>
      <skif-button
        class="ml-6 w-full"
        :disabled="
          (disabledRole && role !== 'EDITOR') ||
          (tableFromParent.key === 'journal' &&
            isHaveSensorParams &&
            isEmptySensorMask) ||
          table.name.length === 0 ||
          (closeToChange && isEmptySensorMask) ||
          (tableFromParent.key === 'chart' && isEmptySensorFillings)
        "
        @click="saveAll"
      >
        {{ $t('btn.save') }}
      </skif-button>
    </div>
    <div
      class="mr-4 ml-6 col-start-1 col-end-3 flex font-semibold mt-4 pb-2 mb-4 text-base border-b-2"
    >
      <!-- Табы -->
      <div
        class="cursor-pointer transition duration-300 mx-4"
        :style="activeTab === 'main' ? { transform: 'scale(1.125)' } : {}"
        :class="
          activeTab === 'main' ? 'text-headerText ' : 'text-annotationColor'
        "
        @click=";(activeTab = 'main'), (isAdding = false)"
      >
        {{ $t('reports.tables.tabs.general') }}
      </div>
      <div
        v-if="
          tableFromParent.key !== 'journal' && tableFromParent.key !== 'chart'
        "
        class="cursor-pointer transition duration-300 mx-4"
        :style="activeTab === 'columns' ? { transform: 'scale(1.125)' } : {}"
        :class="
          activeTab === 'columns' ? 'text-headerText ' : 'text-annotationColor'
        "
        @click=";(activeTab = 'columns'), (isAdding = false)"
      >
        {{ $t('reports.tables.tabs.columns') }}
      </div>
      <div
        v-if="
          tableFromParent.key !== 'journal' && tableFromParent.key !== 'chart'
        "
        class="cursor-pointer transition duration-300 mx-4"
        :style="activeTab === 'sensors' ? { transform: 'scale(1.125)' } : {}"
        :class="
          activeTab === 'sensors' ? 'text-headerText ' : 'text-annotationColor'
        "
        @click=";(activeTab = 'sensors'), (isAdding = false)"
      >
        {{ $t('reports.tables.tabs.sensors') }}
      </div>
      <div
        v-if="
          tableFromParent.key !== 'journal' && tableFromParent.key !== 'chart'
        "
        class="cursor-pointer transition duration-300 mx-4"
        :style="activeTab === 'addColumns' ? { transform: 'scale(1.125)' } : {}"
        :class="
          activeTab === 'addColumns'
            ? 'text-headerText '
            : 'text-annotationColor'
        "
        @click=";(activeTab = 'addColumns'), (isAdding = false)"
      >
        {{ $t('reports.tables.tabs.additional_columns') }}
      </div>
      <div
        v-if="
          tableFromParent.key === 'journal' || tableFromParent.key === 'chart'
        "
        class="cursor-pointer transition duration-300 mx-4"
        :style="activeTab === 'other' ? { transform: 'scale(1.125)' } : {}"
        :class="
          activeTab === 'other' ? 'text-headerText ' : 'text-annotationColor'
        "
        @click="activeTab = 'other'"
      >
        {{ $t('reports.tables.tabs.data') }}
      </div>
      <div
        v-if="reportsWithGeozones.indexOf(table.key) !== -1"
        class="cursor-pointer transition duration-300 mx-4"
        :style="activeTab === 'geozones' ? { transform: 'scale(1.125)' } : {}"
        :class="
          activeTab === 'geozones' ? 'text-headerText ' : 'text-annotationColor'
        "
        @click="activeTab = 'geozones'"
      >
        {{ $t('reports.tables.tabs.geozones') }}
      </div>
    </div>
    <!-- Общее -->
    <div
      v-show="activeTab === 'main'"
      class="pr-4 pl-6 pb-3 col-start-1 col-end-3 flex flex-col overflow-y-auto"
    >
      <template
        v-if="
          activeTab === 'main' &&
          tableFromParent.key !== 'journal' &&
          tableFromParent.key !== 'chart'
        "
      >
        <!-- Тип таблицы -->
        <div
          v-if="table.default_template_table"
          class="flex flex-col mt-3 relative"
        >
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          >
            {{ $t('reports.table.type') }}
          </p>
          <el-input
            v-model="table.default_template_table.name"
            class="mt-3 w-2/3"
            disabled
          />
        </div>
        <!-- Имя -->
        <div class="flex flex-col mt-3 relative">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          >
            {{ $t('reports.tables.fields.name.label') }}
          </p>
          <el-input
            v-model="table.name"
            class="mt-3 w-2/3"
            :disabled="disabledRole && role !== 'EDITOR'"
            :class="{ 'input-error': isNameMissing }"
          />
          <transition name="el-zoom-in-top">
            <div
              v-if="isNameMissing"
              class="text-notify text-xs top-full left-0 absolute"
            >
              {{ $t('reports.tables.fields.name.error') }}
            </div>
          </transition>
        </div>
        <!-- Группировка -->
        <div class="flex flex-col mt-3">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          >
            {{ $t('reports.tables.fields.grouping') }}
          </p>
          <el-select v-model="table.time_group" class="w-2/3">
            <el-option
              v-for="item in tableGrouping"
              :key="item.value"
              :value="item.key"
              :label="item.value"
            />
          </el-select>
        </div>
        <!-- Группировка по полю -->
        <div class="flex flex-col mt-3">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          >
            {{ $t('reports.tables.fields.grouping_by_field') }}
          </p>
          <el-select v-model="groupField" class="w-2/3">
            <el-option
              v-for="(item, index) in itemsField"
              :key="index"
              :value="item.key"
              :label="item.name"
            />
          </el-select>
        </div>
        <!-- Фильтрация -->
        <FilterInterval
          v-if="isFilterInterval"
          :filterIntervals="{
            crop_intervals: table.crop_intervals,
            days: table.weekdays,
            intervals: table.time_intervals
          }"
          @change-intervals="changeInterval"
          @change-interval-days="changeIntervalDays"
          @change-interval-crop="changeIntervalCrop"
        />

        <!-- Маска названия датчиков -->
        <div class="flex flex-col mt-3">
          <div v-if="table.is_masks_acceptable" class="mask-sensors">
            <div
              v-for="(mask, index) in sensorsMask"
              :key="index"
              class="sensors-field mb-3"
            >
              <p
                class="text-annotationColor text-sm font-SourceSansPro font-semibold"
              >
                {{ $t('reports.tables.fields.sensor_name_mask') }}
                {{ index + 1 }}
              </p>
              <div class="field">
                <el-input
                  v-model="mask.value"
                  class="w-2/3"
                  :disabled="disabledRole && role !== 'EDITOR'"
                />
                <TrashIcon
                  v-if="sensorsMask.length > 1 && index !== 0"
                  class="cursor-pointer"
                  @click.native="deleteMaskSensor(index)"
                />
              </div>
            </div>
            <!-- Добавить маску по названию датчиков -->
            <div
              class="add-mask flex align-center cursor-pointer"
              @click="addMaskSensor"
            >
              <AddIcon :is-border="false" />
              <p
                class="text-annotationColor text-sm font-SourceSansPro font-semibold"
              >
                {{ $t('reports.tables.add_mask_sensor') }}
              </p>
            </div>
          </div>

          <skif-checkbox
            class="mt-3"
            v-model="table.show_itog"
            :label="$t('reports.tab-main.show-total')"
          />
          <div>
            <skif-checkbox
              class="mt-3"
              v-model="table.intervals_priority_high"
              :label="$t('reports.tab-main.interval-priority')"
            />
            <el-tooltip
              effect="dark"
              placement="bottom"
              :open-delay="200"
              :offset="0"
              :visible-arrow="true"
            >
              <p
                class="skif-tooltip-text"
                slot="content"
                v-html="$t('reports.tab-main.interval-priority-tooltip')"
              ></p>
              <span class="skif-info-icon">
                <InfoIcon />
              </span>
            </el-tooltip>
          </div>
        </div>
        <div class="flex flex-col mt-3 w-4/5">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-4 mt-3"
          >
            {{ $t('reports.form_for_unloading') }}
          </p>
          <div
            v-if="isOldAttachement"
            class="flex items-center text-headerText uploadedFile justify-between px-2"
            style="width: auto; border-radius: 10px"
            @mouseenter="isFileHover = true"
            @mouseleave="isFileHover = false"
          >
            <span class="font-SourceSansPro mr-2">
              {{ fileName }}
            </span>
            <i
              v-if="isFileHover"
              class="el-icon-circle-close"
              @click="removeFile"
            ></i>
          </div>
          <div class="flex align-start flex-col items-start">
            <el-upload
              class="image-uploader mt-2"
              style="display: flex; align-items: center; height: 40px"
              action=""
              :accept="'.xlsx,.xlsm,.xlsb,.xls'"
              :auto-upload="true"
              :limit="1"
              :http-request="uploadFile"
              :multiple="false"
              :before-upload="beforeUpload"
              :thumbnail-mode="false"
            >
              <skif-button class="mr-2" style="width: 80px">{{
                $t('reports.tables.fields.template_upload')
              }}</skif-button>
            </el-upload>
            <p
              class="underline mt-4 text-base font-SourceSansPro cursor-pointer font-semibold"
              style="color: #3a7cca"
              @click="showListKey"
            >
              {{ $t('reports.show_key') }}
            </p>
          </div>
          <transition name="el-zoom-in-top" />
          <div v-if="isWrongFile" class="text-notify text-xs">
            {{ $t('reports.tables.fields.files_warn') }}
          </div>
        </div>
        <div class="flex flex-col">
          <p
            class="mt-4 text-annotationColor text-sm font-SourceSansPro font-semibold"
          >
            {{ $t('reports.tables.fields.excel_template_name') }}
          </p>
          <el-input
            v-model="excelFileName"
            :disabled="disabledRole && role !== 'EDITOR'"
            :class="['mt-3 w-2/3', { 'input-error': isNameMissing }]"
          />
        </div>
      </template>
      <template
        v-if="
          activeTab === 'main' &&
          (tableFromParent.key === 'journal' || tableFromParent.key === 'chart')
        "
      >
        <!-- Тип таблицы -->
        <div
          v-if="table.default_template_table"
          class="flex flex-col mt-3 relative"
        >
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          >
            {{ $t('reports.tables.tabs.type_table') }}
          </p>
          <el-input
            v-model="table.default_template_table.name"
            class="mt-3 w-full"
            disabled
          />
        </div>
        <!-- Название -->
        <div class="flex flex-col mt-3 relative">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          >
            {{ $t('reports.tables.tabs.table_name') }}
          </p>
          <el-input
            v-model="table.name"
            class="mt-3 w-full"
            :disabled="disabledRole && role !== 'EDITOR'"
            :class="{ 'input-error': isNameMissing }"
          />
          <transition name="el-zoom-in-top">
            <div
              v-if="isNameMissing"
              class="text-notify text-xs top-full left-0 absolute"
            >
              {{ $t('reports.tables.fields.name.error') }}
            </div>
          </transition>
        </div>
        <template v-if="tableFromParent.key === 'chart'">
          <div class="mt-4">
            <el-checkbox
              v-model="isShowFillings"
              :value="isShowFillings"
              class="checkbox block"
              style="color: #5d799e"
            >
              {{ $t('reports.tables.fields.showThefts') }}
            </el-checkbox>
            <el-checkbox
              v-model="isShowThefts"
              :value="isShowThefts"
              class="checkbox"
              style="color: #5d799e"
            >
              {{ $t('reports.tables.fields.showfillings') }}
            </el-checkbox>
          </div>
        </template>
      </template>
    </div>
    <div
      v-if="activeTab === 'other'"
      class="overflow-y-auto pr-4 pl-6 pb-3 col-start-1 col-end-3 flex flex-col"
    >
      <template>
        <div class="flex align-center relative">
          <el-checkbox
            v-model="closeToChange"
            id="closeReport"
            class="justify-center mr-2"
          />
          <label
            class="font-SourceSansPro font-semibold text-sm mr-4"
            for="closeReport"
            style="color: #5d799e"
          >
            {{ $t('reports.tables.tabs.make_close_report') }}
          </label>
          <InfoIconWhite
            class="cursor-pointer"
            @mouseenter.native="isShowHint = true"
            @mouseleave.native="isShowHint = false"
          />
          <div
            v-if="isShowHint"
            class="font-SourceSansPro p-2 text-xs absolute top-0 right-10 w-2/4"
            style="background: #dbebff; border-radius: 6px; color: #20579a"
          >
            {{ $t('reports.tables.tabs.hint') }}
          </div>
        </div>
        <div class="section">
          <p
            class="font-SourceSansPro font-semibold text-xl mt-8 pb-2 border-b-2"
          >
            {{ $t('reports.tables.tabs.mask_sensor') }}
          </p>
          <p
            class="font-SourceSansPro mt-4 text-xs p-2"
            style="background: #dbebff; border-radius: 6px; color: #20579a"
          >
            {{ $t('reports.tables.tabs.mask_sensor_hint') }}
            <a
              class="font-bold underline"
              href="https://wiki.skif.pro"
              target="_blank"
              rel="noopener noreferrer"
            >
              wiki.skif.pro).
            </a>
          </p>
          <div class="masks mt-4">
            <div
              v-for="(mask, index) in sensorNameMask"
              :key="index"
              class="mask mb-4"
            >
              <label
                class="font-SourceSansPro mb-2 inline-block font-semibold text-sm"
                for="input-mask"
              >
                {{ $t('report.tables.tabs.name_mask') }} ({{ index + 1 }}):
              </label>
              <div class="flex align-center">
                <el-input
                  v-model="mask.value"
                  class="w-full inline-block"
                  type="text"
                />
                <TrashIcon
                  :class="[
                    'display-inline cursor-pointer',
                    { 'trash-icon--disabled': sensorNameMask.length <= 1 }
                  ]"
                  @click.native="deleteSensorMask(index)"
                />
              </div>
              <p
                v-if="closeToChange && sensorNameMask[0].value.length === 0"
                class="font-SourceSansPro text-xs"
                style="color: rgb(255, 108, 108)"
              >
                {{ $t('report.tables.tabs.enter_value') }}
              </p>
            </div>
          </div>
          <div class="flex align-center cursor-pointer">
            <AddIconBlue />
            <span
              class="ml-2 font-SourceSansPro font-normal text-sm"
              style="color: #3a7cca"
              @click="addSensorNameMask"
            >
              {{ $t('report.tables.tabs.add_mask') }}</span
            >
          </div>
        </div>
        <div class="section">
          <p
            class="font-SourceSansPro font-semibold text-xl mt-8 pb-2 border-b-2"
          >
            {{ $t('report.tables.tabs.parameters') }}
          </p>
          <p
            class="font-SourceSansPro mt-4 text-xs p-2"
            style="background: #dbebff; border-radius: 6px; color: #20579a"
          >
            {{ $t('report.tables.tabs/parameter_hint') }}
          </p>
          <div class="parameters">
            <div
              v-for="(param, index) in sensorParams"
              :key="index"
              class="parameter"
            >
              <p
                class="font-SourceSansPro mt-4 mb-2 inline-block font-semibold text-sm"
              >
                {{ $t('report.tables.tabs.parameter') }} ({{ index + 1 }}):
              </p>
              <div class="parameter-select flex align-center">
                <label for="protocol"></label>
                <el-select
                  name="protocol"
                  class="w-full mr-3"
                  v-model.trim="param.terminal_type_key"
                  :placeholder="$t('reports.tables.tabs.all_protocol')"
                  id="protocol"
                >
                  <el-option
                    v-for="(type, index) in terminalType"
                    :key="type.key"
                    :label="type.value"
                    :value="type.key"
                  />
                </el-select>
                <el-select
                  name="parameter"
                  v-model="param.value"
                  :placeholder="$t('reports.tables.tabs.parameters')"
                  class="w-full"
                  id=""
                >
                  <el-option
                    v-for="(type, index) in terminalTypeFilter(
                      param.terminal_type_key
                    )"
                    :key="type.index"
                    :label="type.label"
                    :value="type.value"
                  />
                </el-select>
                <TrashIcon
                  :class="[
                    'w-12 cursor-pointer',
                    { 'trash-icon--disabled': sensorParams.length <= 1 }
                  ]"
                  @click.native="deleteSensorParams(index)"
                />
              </div>
              <p
                v-if="
                  closeToChange &&
                  sensorParams[0].value.length === 0 &&
                  sensorParams[0].terminal_type_key.length === 0
                "
                class="font-SourceSansPro text-xs"
                style="color: rgb(255, 108, 108)"
              >
                {{ $t('report.tables.tabs.enter_value') }}
              </p>
            </div>
          </div>
          <div
            :class="[
              'add-parameter',
              'hello',
              'mt-4',
              { 'cursor-pointer': sensorParams.length < 5 },
              { disabled: sensorParams.length === 5 }
            ]"
            @click="addSensorParams"
          >
            <AddIconBlue />
            <span
              class="ml-2 font-SourceSansPro font-normal text-sm"
              style="color: #3a7cca"
            >
              {{ $t('report.tables.tabs.add_parameter') }}
            </span>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="
        activeTab === 'main' &&
        tableFromParent.key === 'chart' &&
        (isShowFillings || isShowThefts)
      "
      class="pr-4 pl-6 pb-3 col-start-1 col-end-3 flex flex-col overflow-auto"
    >
      <div class="parameters">
        <div
          v-for="(value, index) in sensorFillings"
          :key="index"
          class="parameter"
        >
          <p
            class="font-SourceSansPro mt-4 mb-2 inline-block font-semibold text-sm"
          >
            {{ $t('reports,tables.fields.mask_name_sensor') }} ({{
              index + 1
            }}):
          </p>
          <div class="parameter-select w-full flex align-center">
            <div class="flex w-full align-center">
              <el-input
                v-model="value.value"
                class="w-full inline-block"
                type="text"
              />
              <TrashIcon
                :class="[
                  'display-inline cursor-pointer',
                  { 'trash-icon--disabled': sensorFillings.length <= 1 }
                ]"
                @click.native="deleteFillings(index)"
              />
            </div>
          </div>
          <p
            v-if="value.value.length === 0"
            class="font-SourceSansPro text-xs"
            style="color: rgb(255, 108, 108)"
          >
            {{ $t('report.tables.tabs.enter_value') }}
          </p>
        </div>
      </div>
      <div class="add-parameter mt-4 cursor-pointer" @click="addFillings">
        <AddIconBlue />
        <span
          class="ml-2 font-SourceSansPro font-normal text-sm"
          style="color: #3a7cca"
        >
          {{ $t('reports.tables.fields.add_mask') }}
        </span>
      </div>
    </div>

    <div
      v-if="table.key === 'geozones_between' && activeTab === 'geozones'"
      :class="[
        'geozones-banner',
        { 'geozones-banner--error': isGeozonesLength }
      ]"
    >
      {{ $t('reports.tables.fields.geozone_warn') }}
    </div>

    <skif-search
      class="mr-4 ml-6 col-start-1 col-end-3"
      v-show="
        activeTab !== 'main' &&
        activeTab !== 'sensors' &&
        activeTab !== 'addColumns' &&
        activeTab !== 'other'
      "
      :placeholder="$t('search')"
      v-model="filterString"
      @searching="searching"
    />

    <div
      class="mr-4 ml-6 col-start-1 col-end-3 overflow-y-auto my-2"
      v-show="activeTab === 'geozones'"
    >
      <template v-if="table.key === 'geozones_between'">
        <table class="w-full">
          <thead class="text-left">
            <tr class="sticky bg-white z-10">
              <th class="bg-white top-0 sticky">
                <span
                  class="font-bold text-darkblue text-xl font-SourceSansPro"
                >
                  {{ $t('reports.tables.geozones.title') }}
                </span>
              </th>
              <th class="bg-white top-0 sticky">
                <span
                  class="font-bold text-darkblue text-xl font-SourceSansPro"
                >
                  {{ $t('reports.tables.geozones.enter') }}
                </span>
              </th>
              <th class="bg-white top-0 sticky">
                <span
                  class="font-bold text-darkblue text-xl font-SourceSansPro"
                >
                  {{ $t('reports.tables.geozones.exit') }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="border-b border-reallylightblue truncate"
              v-for="(geozone, index) in filterGeozones"
              :key="index"
            >
              <td class="truncate" style="max-width: 100px">
                <el-tooltip
                  effect="dark"
                  placement="bottom"
                  :open-delay="700"
                  :content="geozone.name"
                  :offset="0"
                  :visible-arrow="false"
                >
                  <span
                    class="leading-10 font-bold text-sm transition-colors duration-300 truncate"
                    :class="
                      checkedGeozones.includes(geozone.id)
                        ? 'text-darkblue'
                        : 'text-annotationColor'
                    "
                  >
                    {{ geozone.name }}
                  </span>
                </el-tooltip>
              </td>
              <td class="w-10 flex align-center justify-center">
                <skif-checkbox
                  v-model="geozonesIn"
                  :value="geozone.name"
                  :label="geozone.id"
                  :showLabel="false"
                />
              </td>
              <td class="w-10 text-center">
                <skif-checkbox
                  v-model="geozonesOut"
                  :value="geozone.name"
                  :label="geozone.id"
                  :showLabel="false"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <table class="w-full">
          <thead class="text-left">
            <tr>
              <th class="bg-white top-0 sticky">
                <span
                  class="font-bold text-darkblue text-xl font-SourceSansPro"
                >
                  {{ $t('reports.tables.geozones.title') }}
                </span>
              </th>
              <th class="bg-white top-0 sticky z-10 w-10">
                <skif-checkbox
                  v-model="isAllChecked"
                  :indeterminate="isIndeterminate"
                  :disabled="!filterGeozones.length"
                  @click.native="(event) => event.stopPropagation()"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="border-b border-reallylightblue truncate"
              v-for="(geozone, index) in filterGeozones"
              :key="index"
            >
              <td class="truncate" style="max-width: 100px">
                <el-tooltip
                  effect="dark"
                  placement="bottom"
                  :open-delay="700"
                  :content="geozone.name"
                  :offset="0"
                  :visible-arrow="false"
                >
                  <span
                    class="leading-10 font-bold text-sm transition-colors duration-300 truncate"
                    :class="
                      checkedGeozones.includes(geozone.id)
                        ? 'text-darkblue'
                        : 'text-annotationColor'
                    "
                  >
                    {{ geozone.name }}
                  </span>
                </el-tooltip>
              </td>
              <td class="w-10">
                <skif-checkbox
                  v-model="checkedGeozones"
                  :label="geozone.id"
                  :showLabel="false"
                  @click.native="(event) => event.stopPropagation()"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </div>
    <!-- <div
      class="mr-4 ml-6 col-start-1 col-end-3 overflow-y-auto my-2"
      v-show="activeTab === 'geozones_between'"
    >
      <table class="w-full">
        <thead class="text-left">
          <tr class="sticky bg-white z-10">
            <th class="bg-white top-0 sticky">
              <span class="font-bold text-darkblue text-xl font-SourceSansPro">
                Геозоны
              </span>
            </th>
            <th class="bg-white top-0 sticky">
              <span class="font-bold text-darkblue text-xl font-SourceSansPro">
                Вход
              </span>
            </th>
            <th class="bg-white top-0 sticky">
              <span class="font-bold text-darkblue text-xl font-SourceSansPro">
                Выход
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="border-b border-reallylightblue truncate"
            v-for="(geozone, index) in filterGeozones"
            :key="index"
          >
            <td class="truncate" style="max-width: 100px">
              <el-tooltip
                effect="dark"
                placement="bottom"
                :open-delay="700"
                :content="geozone.name"
                :offset="0"
                :visible-arrow="false"
              >
                <span
                  class="leading-10 font-bold text-sm transition-colors duration-300 truncate"
                  :class="
                    checkedGeozones.includes(geozone.id)
                      ? 'text-darkblue'
                      : 'text-annotationColor'
                  "
                >
                  {{ geozone.name }}
                </span>
              </el-tooltip>
            </td>
            <td class="w-10 flex align-center justify-center">
              <skif-checkbox
                v-model="geozonesIn"
                :value="geozone.name"
                :label="geozone.id"
                :showLabel="false"
              />
            </td>
            <td class="w-10 text-center">
              <skif-checkbox
                v-model="geozonesOut"
                :value="geozone.name"
                :label="geozone.id"
                :showLabel="false"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->

    <div
      class="mr-1 pr-3 ml-6 col-start-1 col-end-3 overflow-y-auto my-2"
      v-show="activeTab === 'columns'"
    >
      <div class="table w-full table-fixed bg-white">
        <div class="table-header-group bg-red-200">
          <div class="table-row z-10">
            <div
              class="table-cell bg-white top-0 sticky text-left w-8 py-2 bg-white z-10"
            >
              <span
                class="font-semibold text-annotationColor text-sm font-SourceSansPro whitespace-no-wrap bg-white"
              >
                {{ $t('reports.tables.columns.fields.column') }}
              </span>
            </div>
            <div
              class="table-cell bg-white top-0 sticky text-center w-6 py-2 bg-white z-10"
            >
              <span
                class="font-semibold text-annotationColor text-sm font-SourceSansPro whitespace-no-wrap bg-white"
              >
                {{ $t('reports.tables.columns.fields.visibility') }}
              </span>
            </div>
            <div
              class="table-cell bg-white top-0 sticky text-left w-6 py-2 bg-white z-10"
            >
              <span
                class="font-semibold text-annotationColor text-sm font-SourceSansPro whitespace-no-wrap bg-white"
              >
                {{ $t('reports.tables.columns.fields.filter') }}
              </span>
            </div>
            <div
              class="table-cell bg-white top-0 sticky text-left w-8 py-2 bg-white z-10"
            >
              <span
                class="font-semibold text-annotationColor text-sm font-SourceSansPro whitespace-no-wrap bg-white"
              >
                {{ $t('reports.tables.columns.fields.min_value') }}
              </span>
            </div>
            <div
              class="table-cell bg-white top-0 sticky text-left w-8 py-2 bg-white z-10"
            >
              <span
                class="font-semibold text-annotationColor text-sm font-SourceSansPro whitespace-no-wrap bg-white"
              >
                {{ $t('reports.tables.columns.fields.max_value') }}
              </span>
            </div>
          </div>
        </div>
        <draggable
          class="table-row-group"
          v-model="filterColumns"
          @change="dragItem"
          ghost-class="ghost"
          drag-class="drag"
          chosen-class="chosen"
        >
          <div
            class="table-row cursor-pointer hover_bg-hoverrow"
            v-for="(column, index) in filterColumns"
            :key="index"
          >
            <div class="table-cell">
              <el-tooltip
                class="w-full"
                effect="dark"
                placement="bottom"
                :disabled="column.name.trim().length <= 0"
                :open-delay="700"
                :content="column.name"
                :offset="0"
                :visible-arrow="false"
              >
                <el-input
                  class="leading-10 font-bold text-sm transition duration-300 text-darkblue w-full"
                  :class="
                    (column.is_visible ? '' : 'opacity-50',
                    column.name.trim().length <= 0 ? 'isEmpty' : '')
                  "
                  v-model="column.name"
                  @change="validateColumn(index, column)"
                />
              </el-tooltip>
              <transition name="el-zoom-in-top">
                <div
                  class="text-notify text-xs"
                  v-if="column.name.trim().length <= 0"
                >
                  {{ $t('reports.tables.columns.fields.name.error') }}
                </div>
              </transition>
            </div>
            <div class="table-cell text-center">
              <svg
                v-if="column.is_visible"
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="clickEye(column)"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3 12c1.415-4.097 4.91-7 9-7s7.584 2.903 9 7c-1.416 4.097-4.91 7-9 7s-7.585-2.903-9-7zm13 0a4 4 0 11-8 0 4 4 0 018 0zm-2 0a2 2 0 11-4 0 2 2 0 014 0z"
                  fill="#5477A9"
                />
              </svg>
              <svg
                v-else
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="clickEye(column)"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.036 15.864l-2.444-2.444-.012-.011-2.445-2.445a4 4 0 004.9 4.9zM5.28 8.107L4 6.82l1.41-1.41 13.18 13.18L17.18 20l-1.732-1.723A8.588 8.588 0 0112 19c-4.09 0-7.585-2.903-9-7a11.297 11.297 0 012.279-3.893zm13.442 7.786l-2.857-2.857a4.005 4.005 0 00-4.9-4.9L8.552 5.722A8.587 8.587 0 0112 5c4.09 0 7.584 2.903 9 7a11.298 11.298 0 01-2.279 3.893z"
                  fill="#5477A9"
                />
              </svg>
            </div>
            <div class="table-cell">
              <skif-switch
                v-if="column.type === 'number' || column.type === 'duration'"
                class="item-button"
                v-model="column.use_filter"
                @click.native.stop
                @change="changeFilter(column)"
              />
            </div>
            <div class="table-cell">
              <skif-input-number
                width="24"
                :disabled="!column.use_filter"
                v-model.number="column.filter_min"
              />
            </div>
            <div class="flex justify-between align-center">
              <skif-input-number
                width="24"
                :disabled="!column.use_filter"
                v-model.number="column.filter_max"
              />
              <DragIcon />
            </div>
          </div>
        </draggable>
      </div>
    </div>

    <div
      class="mr-4 ml-6 col-start-1 col-end-3"
      v-show="activeTab === 'sensors' || activeTab === 'addColumns'"
    >
      <div class="relative">
        <div class="flex justify-between">
          <p class="text-annotationColor text-sm font-SourceSansPro font-bold">
            {{ $t('add') }}
            {{
              activeTab === 'sensors'
                ? $t('reports.tables.tabs.sensors')
                : $t('reports.tables.tabs.columns')
            }}
            {{ $t('in') }} {{ $t('reports.tables.report') }}:
          </p>
          <svg
            :class="[
              'cursor-pointer fill-annotationColor hover_fill-fillTrash transition-all ease-in-out duration-200 transform',
              isAddingFormula ? 'rotate-45' : 'rotate-0'
            ]"
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="addSensor()"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 101.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z"
            />
          </svg>
        </div>
        <el-divider class="mb-6 mt-4" />
      </div>
    </div>

    <div
      class="mr-1 ml-6 col-start-1 col-end-3 sensors"
      v-show="activeTab === 'sensors'"
    >
      <!-- Датчики тамблица - добавление -->
      <template v-if="isAdding">
        <div
          class="mr-3 flex mb-5 sensor-item new-sensor"
          v-for="(item, key) in addSensors"
          :key="`${key}-addSensors`"
        >
          <div
            :class="[
              'sensors-block',
              { 'sensors-block__not-total-value': !isSelectTotalValue }
            ]"
          >
            <div class="relative block1">
              <p
                class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-1"
              >
                {{ $t('objects_tabs.sensors.type_sensor') }}
              </p>
              <el-select
                v-model="item.type"
                clearable="clearable"
                :class="[
                  inputChange ? 'flex' : 'grid',
                  { 'input-error': isSensorType[key] }
                ]"
                @change="changeSensorSelect(isSensorType[key])"
              >
                <el-option
                  class="text-ellipsis"
                  v-for="(item, key) in sensorType"
                  :style="getOptionStyle"
                  :key="key"
                  :label="item.value"
                  :value="item"
                />
              </el-select>
              <transition name="el-zoom-in-top" />
              <div
                v-if="isSensorType[key]"
                class="text-notify text-xs top-full left-0 absolute"
              >
                {{ $t('valid.text') }}
              </div>
            </div>
            <div class="relative block2">
              <p
                class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-1"
              >
                {{ $t('notifications.sensor.maskByName') }}
              </p>
              <el-input v-model="item.maskName" />
            </div>
            <div class="relative block3">
              <p
                class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-1"
              >
                {{ $t('notifications.sensor.meaning') }}
              </p>
              <el-select
                v-model="item.meaning"
                clearable="clearable"
                :class="[
                  inputChange ? 'flex' : 'grid',
                  { 'input-error': isSensorMeaning[key] }
                ]"
                @change="changeSensorSelect(isSensorMeaning[key])"
              >
                <el-option
                  v-for="(item, key) in templateSensorType"
                  class="text-ellipsis"
                  :style="getOptionStyle"
                  :key="key"
                  :label="item.value"
                  :value="item"
                />
              </el-select>
              <transition name="el-zoom-in-top">
                <div
                  class="text-notify text-xs top-full left-0 absolute"
                  v-if="isSensorMeaning[key]"
                >
                  {{ $t('valid.text') }}
                </div>
              </transition>
            </div>
            <div class="relative block4">
              <p
                class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-1"
              >
                {{ $t('reports.tables.columns.fields.name.label') }}
              </p>
              <el-input v-model="item.columnName" />
            </div>
            <div class="relative block5">
              <div class="flex switch align-center">
                <p class="mr-2 sensor-reports-text">
                  {{ $t('reportEdit.sensor.addTotalValue') }}
                </p>
                <!-- <el-switch
                  :value="item.isHaveSensor"
                  @change="item.isHaveSensor = !item.isHaveSensor"
                  class="mr-4"
                /> -->
                <div class="info" @focus="showInfoText" @blur="hideInfoText">
                  <QuestionIcon />
                  <p class="info-text hidden">
                    {{ $t('reportEdit.sensor.hintText') }}
                  </p>
                </div>
              </div>
              <div class="select w-full mt-4">
                <el-select
                  class="w-full"
                  v-model="totalValueReport"
                  :placeholder="$t('new_units.placeholder.select')"
                  @change="changeSelectAddSensorReport(item)"
                >
                  <el-option
                    v-for="item in getSensorValues"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  />
                </el-select>
              </div>
            </div>
            <div class="relative block6"></div>
            <div
              class="relative flex mb-1 mt-3 block7"
              style="align-items: center"
            >
              <skif-button variant="normal" @click="closeFormulaForm">
                {{ $t('cancel') }}
              </skif-button>
              <skif-button
                class="ml-6"
                :disabled="disabledRole && role !== 'EDITOR'"
                @click="save(item)"
              >
                {{ $t('add') }}
              </skif-button>
            </div>
          </div>
        </div>
      </template>

      <!-- Датчики таблица - таблица -->
      <div
        class="mr-3 flex mb-6 sensor-item"
        v-for="(item, index) in table.template_sensors"
        :key="index"
      >
        <div class="sensors-block sensors-block-edit">
          <div class="relative block1">
            <p
              class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-1"
            >
              {{ $t('objects_tabs.sensors.type_sensor') }}
            </p>
            <el-select
              v-model="item.sensor_type.key"
              clearable="clearable"
              :disabled="changedSensor !== item.id"
              :class="[
                inputChange ? 'flex' : 'grid',
                { 'input-error': isSensorType[index] }
              ]"
              @change="changeSensorSelect(isSensorType[index])"
            >
              <el-option
                class="text-ellipsis"
                style="max-width: 160px"
                v-for="(item, key) in sensorType"
                :key="key"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
            <!-- <el-input :disabled="changedSensor !== item.id" v-model="item.sensor_type.value" /> -->
          </div>
          <div class="relative block2">
            <p
              class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-1"
            >
              {{ $t('notifications.sensor.maskByName') }}
            </p>
            <el-input
              :disabled="changedSensor !== item.id"
              v-model="item.name_mask"
            />
          </div>
          <div class="relative block3">
            <p
              class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-1"
            >
              {{ $t('notifications.sensor.meaning') }}
            </p>
            <el-select
              v-model="item.sensor_value.key"
              :disabled="changedSensor !== item.id"
              :class="[
                inputChange ? 'flex' : 'grid',
                { 'input-error': isSensorMeaning[index] }
              ]"
            >
              <!-- @change="changeSensorSelect(isSensorMeaning[index])" -->
              <el-option
                v-for="(item, key) in templateSensorType"
                class="text-ellipsis"
                style="max-width: 160px"
                :key="key"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
            <!-- <el-input :disabled="changedSensor !== item.id" v-model="item.sensor_value.value" /> -->
          </div>
          <div class="relative block4">
            <p
              class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-1"
            >
              {{ $t('reports.tables.columns.fields.name.label') }}
            </p>
            <el-input
              :disabled="changedSensor !== item.id"
              v-model="item.column_name"
            />
          </div>
          <div class="relative block5">
            <div class="flex switch align-center">
              <p class="mr-2 sensor-reports-text">
                {{ $t('reportEdit.sensor.addTotalValue') }}
              </p>
              <!-- <el-switch
                :disabled="changedSensor !== item.id"
                :value="item.isHaveSensor"
                @change="item.isHaveSensor = !item.isHaveSensor"
                class="mr-4"
              /> -->
              <div class="info" @focus="showInfoText" @blur="hideInfoText">
                <QuestionIcon />
                <p class="info-text hidden">
                  {{ $t('reportEdit.sensor.hintText') }}
                </p>
              </div>
            </div>
            <div class="select w-full mt-4">
              <el-select
                :disabled="changedSensor !== item.id"
                class="w-full"
                v-model="item.total_ops"
                :placeholder="$t('new_units.placeholder.select')"
              >
                <el-option
                  v-for="item in getSensorValues"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                />
              </el-select>
            </div>
          </div>
        </div>

        <div
          v-if="!isActionButton"
          class="relative sensor-action flex mb-1 mt-3"
          style="align-items: center"
        >
          <p class="sensor-action__button" @click="deleteSensor(item)">
            {{ $t('delete') }}
          </p>
          <p
            class="sensor-action__button sensor-action__button-change"
            @click="changeSensor(item)"
          >
            {{ $t('change') }}
          </p>
        </div>
        <div
          v-if="isActionButton && changedSensor === item.id"
          class="flex justify-end w-full relative flex mb-1"
          style="align-items: center"
        >
          <skif-button variant="normal" @click="cancelEdit(item)">
            {{ $t('cancel') }}
          </skif-button>
          <skif-button
            class="ml-6"
            :disabled="disabledRole && role !== 'EDITOR'"
            @click="save(item)"
          >
            {{ $t('save') }}
          </skif-button>
        </div>
      </div>
      <div class="mr-3 add-sensor">
        <AddIcon @click.native="addSensor" />
      </div>
    </div>
    <formula
      class="mr-4 ml-6"
      v-if="activeTab === 'addColumns'"
      :table="table"
      :isAdd="isAdding"
      @closeAddingModal="closeAdding"
      @closeformula="addFormula"
      @deleteColumn="removeFormula"
      @wrongformula="wrongFormula"
      @changeColumnName="changeColumnName($event, 'name')"
      @saveFormulaChanges="saveFormulaChanges"
      :isRemoveColSucess="isRemoveColSucess"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
// import Sortable from 'sortablejs'
import { reportsApi } from '@/api'
import draggable from 'vuedraggable'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    draggable,
    DragIcon: () => import('@/components/reports/icons/DragIcon.vue'),
    AddIcon: () => import('@/components/reports/icons/AddIcon.vue'),
    InfoIcon: () => import('@/assets/svg-icons/info.svg'),
    formula: () => import('@/components/reports/formula.vue'),
    TrashIcon: () => import('@/components/reports/icons/TrashIcon.vue'),
    // UnitsSwitch: () =>
    //   import('@/components/unitsNew/components/UnitsSwitch.vue'),
    QuestionIcon: () =>
      import('@/components/unitsNew/assets/icons/question-icon.vue'),
    AddIconBlue: () => import('./icons/AddIconBlue.vue'),
    InfoIconWhite: () => import('./icons/InfoIconWhite.vue'),
    FilterInterval: () => import('./components/FilterInterval.vue')
  },
  props: {
    tableFromParent: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      formulaToDeleteName: '',
      isRemoveColSucess: false,
      isFormulaWrong: false,
      EditedFormula: [],
      isAddingFormula: false,
      formula: [],
      formulas: [],
      columns: {
        name: '',
        formula: ''
      },
      inputChange: false,
      use_filter: false,
      isEdited: true,
      addSensors: [],
      isAdding: false,
      emptyCols: [],
      isFileHover: false,
      isWrongFile: false,
      fileList: [],
      excelFileName: '',
      activeTab: 'main',
      isNameMissing: false,
      isSensorMeaning: [],
      isColumnValue: [],
      isColumnName: [],
      customSensors: {},
      isSensorType: [],
      computableColumns: [],
      groupField: '',
      table: null,
      // itemsField: [],
      filterStringColumns: '',
      filterStringGeozones: '',
      checkedGeozones: [],
      reportsWithGeozones: [
        'geozones',
        'park_utilization',
        'availability',
        'fields_processing',
        'geozones_between'
      ],
      attachements: [],
      isOldAttachement: true,
      fileName: '',
      content: '',
      // serviceCols: '',
      currentColumns: [],
      changedSensor: null,
      templateTables: [],
      isActionButton: false,
      geozonesIn: [],
      geozonesOut: [],
      isGeozonesLength: false,
      tableTemplatesCols: [],
      sensorsMask: [],
      totalValueReport: 'sum',
      isSelectTotalValue: false,
      isAddSelectTotalValue: false,
      sensorValue: '',
      isShowHint: false,
      closeToChange: false,
      sensorNameMask: [],
      sensorParams: [],
      isShowFillings: false,
      isShowThefts: false,
      sensorFillings: [
        {
          value: ''
        }
      ],
      days: [
        {
          key: 'Monday',
          name: this.$t('routesTrips.trip.date.week_days.monday'),
          active: true
        },
        {
          key: 'Tuesday',
          name: this.$t('routesTrips.trip.date.week_days.tuesday'),
          active: true
        },
        {
          key: 'Wednesday',
          name: this.$t('routesTrips.trip.date.week_days.wednesday'),
          active: true
        },
        {
          key: 'Thursday',
          name: this.$t('routesTrips.trip.date.week_days.thursday'),
          active: true
        },
        {
          key: 'Friday',
          name: this.$t('routesTrips.trip.date.week_days.friday'),
          active: true
        },
        {
          key: 'Saturday',
          name: this.$t('routesTrips.trip.date.week_days.saturday'),
          active: true
        },
        {
          key: 'Sunday',
          name: this.$t('routesTrips.trip.date.week_days.sunday'),
          active: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters('login', ['role', 'disabledRole']),
    ...mapGetters('dictionary', [
      'tableGrouping',
      'getSensorValues',
      'terminalType',
      'getParamsByKey'
    ]),
    ...mapGetters('geozones', {
      geozones: 'geozonesIdNameImei'
    }),
    ...mapState('dictionary', {
      sensorType: (state) => state.sensor_type,
      templateSensorType: (state) => state.template_sensor_value_type
    }),
    isFilterInterval() {
      return !['journal', 'chart', 'analytics_units_events', 'notifications', 'messages'].includes(this.tableFromParent.key)
    },
    terminalTypeFilter() {
      return (terminalTypeKey) => {
        if (terminalTypeKey) {
          return this.getParamsByKey(terminalTypeKey).filter(
            (param) => !this.sensorParams.some((p) => p.value === param.value)
          )
        }
        return this.getParamsByKey
      }
    },
    getOptionStyle() {
      return {
        'max-width': `${Math.min(400, window.innerWidth - 100)}px`
      }
    },
    itemsField() {
      const filtredItems = this.tableTemplatesCols.filter(
        (item) => item.can_be_grouped
      )
      return [
        { key: null, name: this.$t('reports.without_grouping') },
        ...filtredItems
      ]
    },
    filterColumns: {
      get() {
        return this.templateTables.filter(
          (el) =>
            el.name
              .toLowerCase()
              .includes(this.filterStringColumns.toLowerCase()) &&
            el.key.charAt(0) !== '_'
        )
      },

      set(val) {
        this.templateTables = val
      }
    },
    filterGeozones() {
      return this.geozones.filter((geozone) =>
        JSON.stringify(geozone)
          .toLowerCase()
          .includes(this.filterStringGeozones.toLowerCase())
      )
    },
    geozonesIds() {
      return this.filterGeozones.map((k) => k.id)
    },

    isAllChecked: {
      get() {
        return (
          this.checkedGeozones.length >= this.filterGeozones.length &&
          this.filterGeozones.length
        )
      },
      set(value) {
        this.checkedGeozones = value ? this.geozonesIds : []
      }
    },
    isIndeterminate() {
      return (
        this.checkedGeozones.length > 0 &&
        this.checkedGeozones.length < this.filterGeozones.length
      )
    },

    filterString: {
      get() {
        return this.activeTab === 'columns'
          ? this.filterStringColumns
          : this.filterStringGeozones
      },
      set(value) {
        if (this.activeTab === 'columns') {
          this.filterStringColumns = value
        } else {
          this.filterStringGeozones = value
        }
      }
    },
    isEmptySensorMask() {
      return (
        this.sensorNameMask[0] &&
        this.sensorNameMask[0].value !== undefined &&
        this.sensorNameMask[0].value === ''
      )
    },
    isEmptySensorParams() {
      if (this.activeTab === 'other') {
        if (!this.sensorParams[0]) {
          return true
        }
        if (this.sensorParams[0].value === undefined) {
          return true
        }
        return this.sensorParams[0].value === ''
      }
      return false
    },
    isHaveSensorParams() {
      return this.isEmptySensorParams
    },
    isEmptySensorFillings() {
      if (this.isShowFillings || this.isShowThefts) {
        if (!this.sensorFillings[0]) {
          return true
        }
        if (this.sensorFillings[0].value === undefined) {
          return true
        }
        return this.sensorFillings[0].value === ''
      }
      return false
    }
  },

  watch: {
    closeToChange(val) {
      this.$store.commit('reports/SET_CLOSE_TO_CHANGE', val)
    },
    activeTab(val) {
      this.isEdited = false
      this.isAddingFormula = false
    },
    isAddingFormula(val) {
      if (val) {
        this.isAdding = true
      } else {
        this.isAdding = false
        this.computableColumns = this.table.template_computable_columns
        this.isFormulaWrong = false
      }
    },

    formula(val) {
      // console.log(val.join(''))
    },
    'table.name': function (val) {
      this.isNameMissing = val.trim() === ''
    },
    'column.name': function () {
      this.validColumn()
    },
    'column.value': function () {
      this.validColumn()
    },
    'formula.name': function (val) {
      // console.log(val)
    },

    isAdding(val) {
      if (!val) {
        // this.computableColumns = []
        this.addSensors = []
        this.isSensorType = []
        this.isSensorMeaning = []
        this.isColumnValue = []
        this.isColumnName = []
      }
    },

    geozonesIn(newVal) {
      newVal.forEach((el) => {
        const index = this.geozonesOut.indexOf(el)
        if (index !== -1) {
          this.geozonesOut.splice(index, 1)
        }
      })
    },

    geozonesOut(newVal) {
      newVal.forEach((el) => {
        const index = this.geozonesIn.indexOf(el)
        if (index !== -1) {
          this.geozonesIn.splice(index, 1)
        }
      })
    },

    excelFileName(newValue, oldValue) {
      if (
        newValue &&
        oldValue &&
        newValue !== oldValue &&
        this.table.attachments &&
        this.table.attachments.length
      ) {
        this.table.attachments[0].name = this.excelFileName
      }
    },
    isShowFillings(val) {
      if (!val) {
        this.sensorFillings = [
          {
            value: ''
          }
        ]
      }
    },
    isShowThefts(val) {
      if (!val) {
        this.sensorFillings = [
          {
            value: ''
          }
        ]
      }
    }
  },

  methods: {
    ...mapActions({
      getValuesSensor: 'dictionary/getDictionaryByType'
    }),

    changeInterval(interval) {
      console.log(interval, 'interval')
      const result = interval.map((item) => ({
        from: item.from,
        to: item.to
      }))
      this.table.time_intervals = result
    },

    changeIntervalDays(days) {
      const result = days.map((item) => item.dayNumber)
      this.table.weekdays = result
    },

    changeIntervalCrop(isCrop) {
      this.table.crop_intervals = isCrop
    },

    addFillings() {
      this.sensorFillings.push({ value: '' })
    },

    addSensorNameMask() {
      this.sensorNameMask.push({ value: '' })
    },

    addSensorParams() {
      if (this.sensorParams.length < 5) {
        this.sensorParams.push({
          terminal_type_key: '',
          value: ''
        })
      }
    },

    deleteFillings(index) {
      this.sensorFillings.splice(index, 1)
    },

    deleteSensorMask(index) {
      this.sensorNameMask.splice(index, 1)
    },

    deleteSensorParams(index) {
      this.sensorParams.splice(index, 1)
    },

    showListKey() {
      this.$emit('show-list-key', this.tableFromParent.id)
    },

    changeSelectTotalValue(event, item) {
      this.table.template_sensors.map((el) => {
        if (item.id === el.id) {
          el.isHaveSensor = event
        }
        return el
      })
      // this.isSelectTotalValue = event
    },

    changeSwitchAddSensorReport(event, item) {
      this.addSensors.map((el) => {
        if (item.id === el.id) {
          el.isHaveSensor = event
        }
        return el
      })
      this.isAddSelectTotalValue = event
    },

    changeSelectAddSensorReport(item) {
      this.addSensors.map((el) => {
        if (item.id === el.id) {
          el.total_ops = this.totalValueReport
        }
        return el
      })
      this.isAddSelectTotalValue = true
    },

    showInfoText() {
      const info = document.querySelector('.info')
      info.classList.add('hovered')
    },

    hideInfoText() {
      const info = document.querySelector('.info')
      info.classList.remove('hovered')
    },

    saveFormulaChanges(columns) {
      this.table.template_computable_columns = columns
    },

    clickEye(item) {
      item.is_visible = !item.is_visible
      this.table[item.rightType] = this.table[item.rightType].map((val) => {
        if (val.id === item.id) {
          val.is_visible = item.is_visible
        }

        return val
      })
    },

    changeFilter(item) {
      this.table.template_columns = this.table.template_columns.map((val) => {
        if (val.id === item.id) {
          val.use_filter = item.use_filter
        }

        return val
      })
    },

    beforeSaveTemplates() {
      if (this.table.template_columns) {
        const template_columns = []
        this.templateTables.forEach((el) => {
          this.table.template_columns.forEach((item) => {
            if (el.id === item.id) {
              template_columns.push(el)
            }
          })
        })
        this.table.template_columns = template_columns
      }

      if (this.table.template_sensors) {
        const template_sensors = []
        this.templateTables.forEach((el) => {
          this.table.template_sensors.forEach((item) => {
            if (el.id === item.id) {
              template_sensors.push({
                ...el,
                column_name: el.name
              })
            }
          })
        })
        this.table.template_sensors = template_sensors
      }

      if (this.table.template_computable_columns) {
        const template_computable_columns = []
        this.templateTables.forEach((el) => {
          this.table.template_computable_columns.forEach((item) => {
            if (el.id === item.id) {
              template_computable_columns.push(el)
            }
          })
        })
        this.table.template_computable_columns = template_computable_columns
      }
    },

    columnTemplateSort(item, type) {
      const element = this.table[type].findIndex(
        (el) => el.id === item.element.id
      )
      if (element !== -1) {
        this.table[type][element].sort = item.newIndex
      }

      this.templateTables = this.templateTables.map((el, index) => {
        const newSort = index + 1
        el.hasChanged = el.sort !== newSort
        if (el.hasChanged) {
          el.sort = newSort
        }
        return el
      })

      this.beforeSaveTemplates()
    },

    dragItem({ moved }) {
      const type = moved.element.rightType
      const sortTable = {
        template_columns: this.columnTemplateSort,
        template_sensors: this.columnTemplateSort,
        template_computable_columns: this.columnTemplateSort
      }

      sortTable[type](moved, type)
    },

    wrongFormula(val) {
      this.isFormulaWrong = val
    },

    searching(val) {
      this.filterString = val
    },

    getCurrentColumns(columns) {
      this.currentColumns = columns
    },

    closeAdding() {
      this.isAdding = false

      this.isAddingFormula = false
    },

    addFormula(columns) {
      this.closeAdding()
      this.computableColumns = columns
      this.getCurrentColumns(columns)
    },

    changeSensorSelect(val) {
      this.inputChange = !this.inputChange
      if (val) {
        this.validSensor()
      }
    },

    changeColumnName(val, type = 'column_name') {
      let index = -1

      if (this.templateTables && this.templateTables.length) {
        index = this.templateTables.findIndex((item) => val.id === item.id)

        if (index >= 0) {
          this.templateTables[index].name = val[type]
          this.templateTables[index].column_name = val[type]
        }
      }
      if (index < 0) {
        this.templateTables.unshift(val)
      }
    },

    validateColumn(index, val) {
      if (this.table.template_sensors && this.table.template_sensors.length) {
        this.table.template_sensors = this.table.template_sensors.map(
          (item) => {
            if (val.id === item.id) {
              item.column_name = val.name
            }

            return item
          }
        )
      }

      if (
        this.table.template_computable_columns &&
        this.table.template_computable_columns.length
      ) {
        this.table.template_computable_columns =
          this.table.template_computable_columns.map((item) => {
            if (val.id === item.id) {
              item.column_name = val.name
              item.name = val.name
            }

            return item
          })
      }

      if (this.table.template_columns && this.table.template_columns.length) {
        this.table.template_columns = this.table.template_columns.map(
          (item) => {
            if (val.id === item.id) {
              item.name = val.name
            }

            return item
          }
        )
      }

      if (!val.name.trim().length) {
        this.emptyCols.push(this.filterColumns[index].id)
      } else {
        this.emptyCols = this.emptyCols.filter(
          (x) => x !== this.filterColumns[index].id
        )
      }
    },

    addSensor() {
      this.isAdding = true
      this.isAddingFormula = !this.isAddingFormula

      if (this.activeTab === 'addColumns') {
        this.isEdited = false

        // this.isColumnValue.push(false)
        // this.isColumnName.push(false)
      } else {
        this.sensor = {}
        this.isEdited = false
        this.addSensors.push({
          type: '',
          maskName: '',
          meaning: '',
          columnName: '',
          isHaveSensor: false,
          total_ops: 'sum'
        })
        this.isSensorType.push(false)
        this.isSensorMeaning.push(false)
      }
      this.$nextTick(() => {
        const addSensor = document.querySelector('.new-sensor')
        if (addSensor) {
          addSensor.scrollIntoView({ block: 'center', behavior: 'smooth' })
        }
        this.totalValueReport = 'sum'
      })
    },

    removeFile() {
      this.isOldAttachement = !this.isOldAttachement
      this.attachements.url = ''
      this.attachements.id = ''
      this.attachements.name = this.excelFileName
      this.excelFileName = ''
      this.table.attachments = []
    },

    beforeUpload(file) {
      const isTextComputer =
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel.sheet.macroEnabled.12' ||
        file.type === 'application/vnd.ms-excel.sheet.macroenabled.12' ||
        file.type === 'application/vnd.ms-excel.sheet.binary.macroenabled.12' ||
        file.type === 'application/vnd.ms-excel'
      if (!isTextComputer) {
        this.isWrongFile = true
      } else {
        this.isOldAttachement = false
        this.isWrongFile = false
      }
      return isTextComputer
    },

    uploadFile(item) {
      const fileObj = item.file
      const form = new FormData()
      form.append('file', fileObj)
      reportsApi.uploadAttachments(form, (response) => {
        const attachement = {
          id: response.data.id,
          url: response.data.url,
          name: response.data.name
          // file_name: response.data.name,
        }

        this.attachements = attachement
      })
    },

    valid() {
      this.isNameMissing = this.table.name.trim() === ''
      return !this.isNameMissing
    },

    validColumn() {
      let error = false
      this.computableColumns.forEach((val, index) => {
        if (!val.name) {
          this.isColumnName.splice(index, 1, true)
          error = true
        } else {
          this.isColumnName.splice(index, 1, false)
        }
        if (!val.value) {
          this.isColumnValue.splice(index, 1, true)
          error = true
        } else {
          this.isColumnValue.splice(index, 1, false)
        }
      })
      return error
    },

    validSensor() {
      let error = false
      this.addSensors.forEach((val, index) => {
        if (!val.type) {
          this.isSensorType.splice(index, 1, true)
          error = true
        } else {
          this.isSensorType.splice(index, 1, false)
        }
        if (!val.meaning) {
          this.isSensorMeaning.splice(index, 1, true)
          error = true
        } else {
          this.isSensorMeaning.splice(index, 1, false)
        }
      })
      return error
    },
    // removeFormula(id) {
    //   console.log(1, this.table)
    //   this.templateTables = this.computableColumns = this.templateTables.filter(
    //     (val) => val.id !== id
    //   )
    //   console.log(this.computableColumns)
    //   // this.computableColumns = data
    // },

    removeFormula(formulaToDelete) {
      if (this.isFormulaUsedInComputableColumns(formulaToDelete)) {
        const errorMessage = `Невозможно удалить колонку (${this.formulaToDeleteName}) , так как она используется в дополнительных колонках.`
        this.$showError(errorMessage)
        return
      }

      this.removeFormulaFromTables(formulaToDelete)
    },
    isFormulaUsedInComputableColumns(formulaId) {
      const formula = this.table.template_computable_columns.find(
        (f) => f.id === formulaId
      )

      if (!formula) {
        console.warn(`Formula with ID ${formulaId} not found.`)
        return false
      }
      this.formulaToDeleteName = formula.name
      return this.table.template_computable_columns.some((column) => {
        return this.extractFormulaName(column.value) === formula.name
      })
    },

    extractFormulaName(value) {
      const matches = value.match(/\{computable_columns\.([^\}]+)\}/)

      return matches ? matches[1] : null
    },

    removeFormulaFromTables(formula) {
      this.templateTables = this.templateTables.filter(
        (val) => val.id !== formula.id
      )
      this.computableColumns = this.computableColumns.filter(
        (val) => val.id !== formula.id
      )
      this.isRemoveColSucess = !this.isRemoveColSucess
    },

    deleteSensor(sensorToDelete) {
      if (this.isSensorUsedInComputableColumns(sensorToDelete)) {
        this.$showError(
          `Невозможно удалить датчик(${sensorToDelete.column_name}), так как он используется в дополнительных колонках`
        )
        return
      }

      this.removeSensorFromTables(sensorToDelete)
      this.$store.dispatch(
        'reports/SAVE_TEMPLATES_SENSOR',
        this.table.template_sensors
      )
    },

    isSensorUsedInComputableColumns(sensor) {
      return this.table.template_computable_columns.some((column) => {
        const sensorNameFromColumn = this.extractSensorName(column.value)
        return sensorNameFromColumn === sensor.column_name
      })
    },

    extractSensorName(value) {
      const matches = value.match(/\{sensors\.([^\}]+)\}/)
      return matches ? matches[1] : null
    },

    removeSensorFromTables(sensor) {
      this.table.template_sensors = this.table.template_sensors.filter(
        (val) => val.id !== sensor.id
      )
      this.templateTables = this.templateTables.filter(
        (val) => val.id !== sensor.id
      )
    },
    saveAll() {
      // this.table.template_columns = this.table.template_columns.map((val) => {
      //   if (val.id === item.id) {
      //     val.use_filter = item.use_filter
      //   }

      if (this.valid()) {
        this.table.column_group = this.groupField
        const newCols = this.table.template_columns.map((item) => {
          const new_col = this.filterColumns.find((col) => col.id === item.id)
          return { ...item, ...new_col }
        })
        this.table.template_columns = newCols

        if (this.sensorsMask && this.sensorsMask.length) {
          this.table.sensor_name_masks = this.sensorsMask
        }

        if (this.attachements.id) {
          this.attachements.name = this.excelFileName
            ? this.excelFileName
            : this.attachements.name
          if (this.table.attachments) {
            this.table.attachments.push(this.attachements)
          } else {
            this.table.attachments = [this.attachements]
          }
        }

        if (
          this.activeTab === 'geozones' &&
          this.table.key === 'geozones_between'
        ) {
          this.table.geozones_in = []
          this.table.geozones_out = []

          if (this.geozonesIn.length === 0 && this.geozonesOut.length === 0) {
            this.isGeozonesLength = true
            return
          }
          if (this.geozonesIn.length) {
            this.geozonesIn.forEach((id) => {
              this.table.geozones_in.push({ id })
            })
          }

          if (this.geozonesOut.length) {
            this.geozonesOut.forEach((id) => {
              this.table.geozones_out.push({ id })
            })
          }

          this.$emit('update-table', this.table)
        } else {
          this.table.geozones = []
          this.checkedGeozones.forEach((geozone) => {
            this.table.geozones.push({ id: geozone })
          })
          this.filterColumns = []
          if (this.activeTab === 'other') {
            this.table.closed_to_change = this.closeToChange
            this.table.params = this.sensorParams
            this.table.sensor_name_masks = this.sensorNameMask
          }

          if (
            this.activeTab === 'main' &&
            this.tableFromParent.key === 'chart'
          ) {
            this.table.fuel_sensor_name_masks = this.sensorFillings
            this.table.show_thefts = this.isShowThefts
            this.table.show_fillings = this.isShowFillings
          }

          this.$emit('update-table', this.table)
        }
      } else {
        this.$showError(this.$t('reports.tables.fields.name.error'))
      }
    },
    updateSensorNameInComputableColumns(oldName, newName) {
      this.table.template_computable_columns =
        this.table.template_computable_columns.map((column) => {
          const updatedValue = column.value.replace(
            `{sensors.${oldName}}`,
            `{sensors.${newName}}`
          )
          return { ...column, value: updatedValue }
        })
    },
    save(item) {
      if (this.valid() && !this.isWrongFile && !this.emptyCols.length) {
        if (this.activeTab === 'sensors' || this.activeTab === 'addColumns') {
          this.validSensor()
          if (this.activeTab === 'sensors' && !this.validSensor()) {
            if (this.isAdding) {
              this.addSensors.forEach((val) => {
                if (val.columnName) {
                  const displayedValue = `Датчики/${val.columnName}`
                  const backendValue = `sensors.${val.columnName}`
                  const savedValues = {
                    [backendValue]: displayedValue
                  }
                  this.$store.dispatch('reports/set_sensor_column', savedValues)
                }

                const data = {
                  id: uuidv4(),
                  name_mask: val.maskName,
                  sensor_type: val.type,
                  sensor_value: val.meaning,
                  column_name: val.columnName,
                  name: val.columnName,
                  filter_min: 0,
                  filter_max: 1000,
                  is_visible: true,
                  key: '',
                  isHaveSensor: val.isHaveSensor,
                  total_ops: val.total_ops
                }
                this.table.template_sensors.unshift(data)
                this.templateTables.unshift(data)
              })

              this.$store.dispatch(
                'reports/SAVE_TEMPLATES_SENSOR',
                this.table.template_sensors
              )

              this.isAdding = false
              this.isAddingFormula = false
            } else {
              const newName = this.table.template_sensors.find(
                (el) => el.id === this.changedSensor
              ).column_name
              const oldName = this.customSensors.column_name
              this.updateSensorNameInComputableColumns(oldName, newName)
              this.$store.commit('reports/remove_sensorCol', oldName)

              const displayedValue = `Датчики/${newName}`
              const backendValue = `sensors.${newName}`
              const savedValues = {
                [backendValue]: displayedValue
              }
              this.$store.commit('reports/add_sensor_column', savedValues)

              this.changeColumnName(item)
              this.table.template_sensors = this.table.template_sensors.map(
                (el) => {
                  if (el.id === item.id) {
                    el = {
                      ...item,
                      total_ops: item.total_ops ? item.total_ops : 'empty'
                    }
                  }
                  return el
                }
              )
            }

            this.changedSensor = ''
            this.isActionButton = false
          } else if (this.activeTab === 'addColumns') {
            if (this.isFormulaWrong) return
            let valueString
            if (this.computableColumns.length) {
              this.table.template_computable_columns = []
              this.computableColumns.forEach((val) => {
                if (Array.isArray(val.value)) {
                  valueString = val.value.join('')
                } else {
                  valueString = val.value
                }
                const data = {
                  id: this.table.id,
                  value: valueString,
                  name: val.name
                }

                this.table.template_computable_columns.push(data)
              })
            }

            this.$emit('update-table', this.table)
            this.isAdding = false
          }
        } else {
          this.attachements = { ...this.attachements, name: this.excelFileName }

          this.table.attachments = []
          //  Multi Attachements should cancel Emptying the Array
          this.table.attachments.push(this.attachements)
          this.table.geozones = this.checkedGeozones.map((id) => {
            return {
              id
            }
          })
          this.$emit('update-table', this.table)
        }
      }
    },

    changeSensor(item) {
      this.customSensors = JSON.parse(JSON.stringify(item))

      this.changedSensor = item.id
      this.isActionButton = true
    },

    closeFormulaForm() {
      this.isAddingFormula = false
      this.isAdding = false
    },

    cancelEdit(item) {
      this.table.template_sensors = this.table.template_sensors.map((val) => {
        if (item.id === val.id) {
          this.$set(val, 'sensor_type', this.customSensors.sensor_type)
          this.$set(val, 'sensor_value', this.customSensors.sensor_value)
          this.$set(val, 'column_name', this.customSensors.column_name)
          this.$set(val, 'name_mask', this.customSensors.name_mask)
        }

        return val
      })

      this.isActionButton = false
      this.changedSensor = ''
    },
    addMaskSensor() {
      this.sensorsMask.push({ value: '' })
    },
    deleteMaskSensor(index) {
      this.sensorsMask.splice(index, 1)
    },
    close() {
      this.sensorNameMask = []
      this.sensorParams = []
      this.sensorFillings = []
      this.$emit('close')
    }
  },

  async created() {
    this.tableTemplatesCols = [...this.tableFromParent.template_columns]
    this.table = JSON.parse(JSON.stringify(this.tableFromParent))

    await this.$store.dispatch('reports/get_formulas_list', this.table.key)
    if (this.table.template_computable_columns) {
      this.table.template_computable_columns.map((column) =>
        this.$store.dispatch('reports/add_extrafield', column.name)
      )
    }

    const columnSensors = this.tableFromParent.template_sensors

    if (this.table.template_sensors && this.table.template_sensors.length) {
      columnSensors.forEach((val) => {
        this.isSensorType.push(false)
        if (val.column_name !== '') {
          const displayedValue = `Датчики/${val.column_name}`
          const backendValue = `sensors.${val.column_name}`
          const savedValues = {
            [backendValue]: displayedValue
          }
          this.$store.commit('reports/add_sensor_column', savedValues)
        }
      })
    }

    this.groupField = this.table.column_group || null

    const att = this.tableFromParent.attachments
    if (att && att.length > 0) {
      if (att[0] && att[0].hasOwnProperty('url')) {
        if (att[0].url === null || att[0].url === '') {
          // console.log('no url')
        } else {
          this.fileName = this.tableFromParent.attachments[0].name
        }
      }
      this.excelFileName = this.tableFromParent.attachments[0].name || ''
    }
    if (this.tableFromParent && this.tableFromParent.attachements)
      this.excelFileName = this.tableFromParent.attachments[0].name || ''
    if (this.table.isAdmin) {
      this.$store.commit('geozones/RESET_GEOZONES', this.table.geozones)
      // this.checkedGeozones.push()
    }

    this.geozonesIn = this.table.geozones_in.map((el) => el.id)
    this.geozonesOut = this.table.geozones_out.map((el) => el.id)
    this.checkedGeozones = this.table.geozones.map((k) => k.id)
    this.geozones.sort((a, b) =>
      this.checkedGeozones.indexOf(b.id) !== -1 ? 1 : -1
    )

    let templateColumns = []
    let templateSensors = []
    let templateComputeableColumns = []

    if (this.table.template_columns) {
      templateColumns = this.table.template_columns.map((el) => {
        el.rightType = 'template_columns'
        return el
      })
    }

    if (this.table.template_sensors) {
      templateSensors = this.table.template_sensors.map((el) => {
        el.rightType = 'template_sensors'
        return el
      })
    }

    if (this.table.template_computable_columns) {
      templateComputeableColumns = this.table.template_computable_columns.map(
        (el) => {
          el.rightType = 'template_computable_columns'
          return el
        }
      )
    }

    this.templateTables = [
      ...templateColumns,
      ...(templateSensors || []),
      ...(templateComputeableColumns || [])
    ]

    this.templateTables = this.templateTables
      .map((el) => {
        return {
          ...el,
          name: el.name || el.column_name,
          sort: el.sort || '',
          key: el.key || '',
          id: el.id
        }
      })
      .sort((a, b) => (a.sort < b.sort ? -1 : 1))

    if (
      this.table.is_masks_acceptable &&
      (!this.table.sensor_name_masks ||
        this.table.sensor_name_masks.length === 0)
    ) {
      this.table.sensor_name_masks = this.sensorsMask
      this.sensorsMask.push({ value: '' })
    } else {
      this.sensorsMask = this.table.sensor_name_masks
    }

    this.getValuesSensor('report_column_total_type')

    this.table.template_sensors = this.table.template_sensors.map((el) => {
      return {
        ...el,
        isHaveSensor: !!(el.total_ops && el.total_ops !== 'empty'),
        total_ops: el.total_ops === 'empty' ? 'sum' : el.total_ops
      }
    })
    this.closeToChange = this.tableFromParent.closed_to_change

    this.sensorParams =
      this.tableFromParent.params && this.tableFromParent.params.length > 0
        ? this.tableFromParent.params
        : [{ terminal_type_key: '', value: '' }]

    this.sensorNameMask =
      this.tableFromParent.sensor_name_masks &&
      this.tableFromParent.sensor_name_masks.length > 0
        ? this.tableFromParent.sensor_name_masks
        : [{ value: '' }]

    this.sensorFillings =
      this.tableFromParent.fuel_sensor_name_masks.length > 0
        ? this.tableFromParent.fuel_sensor_name_masks
        : [{ value: '' }]
    this.isShowFillings = this.tableFromParent.show_fillings
    this.isShowThefts = this.tableFromParent.show_thefts
  },

  beforeDestroy() {
    this.sensorParams = []
    this.sensorNameMask = []
    this.sensorFillings = []
  }
}
</script>
<style lang="stylus">
.disabled {
  opacity: 0.5
}
.trash-icon {
  &--disabled {
    opacity 0.4
    pointer-events none
  }
}
.formulaTooltip {
  width: 500px;
}

.el-upload-list__item-name {
  margin-bottom: 10px;
  margin-left: 10px;
}

.uploadedFile:hover {
  background-color: #EEF5FF;
}

.skif-tooltip-text {
  margin: 3px 6px;
  font-size: 13px;
  line-height: 1.6;
}

.isEmpty input {
  border: 1px solid red !important;
}
</style>

<style lang="stylus" scoped>
.mainDiv {
  resize: horizontal;
  overflow: auto;
  min-width: 570px;
  width: 570px;
}

.sensors-block {
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 20px;
  grid-template-areas:
    "block1 block2" \
    "block3 block4" \
    "block5 block5" \
    "block6 block7";
  &__not-total-value {
    grid-template-rows: 1fr 1fr;
  }

  .block1 {
    grid-area: block1;
  }
  .block2 {
    grid-area: block2;
  }
  .block3 {
    grid-area: block3;
  }
  .block4 {
    grid-area: block4;
  }
  .block5 {
    grid-area: block5;
    .sensor-reports-text {
      color: #80ABE1;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
    }
  }
  .block6 {
    grid-area: block6;
  }
  .block7 {
    grid-area: block7;
  }
}

.sensors-block-edit {
  grid-template-rows: 1fr 1fr;
}

.sensor-item {
  flex-wrap: wrap;
  padding: 16px 20px;
  border: 1px solid #D7DAE1;
}

.skif-info-icon {
  margin: 0 0 0 6px;
  vertical-align: middle;
}

.sensors {
  height: auto;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-color: rgba(51, 51, 51, 0.3) #ffffff;
  scrollbar-width: thin;
  padding-right: 5px;
  padding-bottom: 5px;
}

.is-hide-button {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(51, 51, 51, 0.3);
  border-radius: 4px;
}

.align-center {
  align-items: center;
}

.add-sensor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 510px;
  height: 212px;
  border: 1px solid #D7DAE1;
  border-radius: 2px;
}

.sensor-action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;

  &__button {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #BF3131;
    cursor: pointer;
  }

  &__button-change {
    margin-left: 30px;
    color: #436C9D;
  }
}

.chosen {
  opacity: 1;
  background: white;
}

.drag {
  opacity: 1;
  background: white;
}

.ghost {
  background: linear-gradient(0deg, #F8F8F8, #F8F8F8);

  div {
    opacity: 0;
  }
}

.geozones-banner {
  width: 100%;
  margin-left: 24px;
  margin-bottom: 12px;
  padding: 10px 63px;
  color: #20579A;
  border-radius: 3px;
  background: #DBEBFF;
  font-weight: 400;
  font-size: 12px;
  text-align: center;

  &--error {
    background: #FFDBDB;
    color: #B62424;
  }
}
.add-mask {
  margin-left: -11px
}
.info-text {
  position: absolute;
  right: 20px;
  width 295px;
  z-index: 10;
  margin-top: 5px;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #2767B6;
  background: #DBEBFF;
  border-radius: 6px;
}
.info:hover .info-text {
  display: block;
}
:deep(.el-input.is-disabled .el-input__inner) {
  background-color: #E9EFF3;
  color: #6A7987;
  font-family: 'Source Sans Pro';
}
</style>

<style lang="scss">
.image-uploader {
  display: flex;
  .el-upload {
    display: flex;
    align-items: center;
  }
}
</style>
